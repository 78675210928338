<template>
  <div class="flex items-center relative space-x-2">
    <button
      v-if="column.filterComponent.component"
      type="button"
      class="w-6 h-6 rounded"
      :class="filterIconClasses"
      :data-cy="`table-header-filter-${column.key}-filter`"
    >
      <icon-base
        :icon="IconFilter"
        height="12"
        width="12"
        viewBox="0 0 16 16"
      />
      <div
        v-show="toggleFilter"
        class="absolute z-50"
        :class="{ 'pointer-events-none': !toggleFilter }"
      >
        <component
          :is="column.filterComponent.component"
          v-if="
            initialComponentRender || (!initialComponentRender && toggleFilter)
          "
          v-on-click-outside="onClickOutsideHandler"
          v-bind="{
            ...(column.filterComponent.props || {})
          }"
          :toggle-from-parent="toggleFilter"
          :is-loading="isLoading"
          :model-value:is-exclude-mode-enabled="
            column.filterComponent.props.isExcludeModeEnabled
          "
          @update:model-value="column.filterComponent.onValueChange"
          @change="column.filterComponent.onChange"
          @loaded-more="column.filterComponent.loadedMore"
          @options-search="column.filterComponent.optionsSearch"
          @toggle-visible="handleFilterVisibilityChange"
          @update:isExcludeModeEnabled="
            column.filterComponent.excludeModeChanged
          "
        ></component>
      </div>
    </button>
    <span class="flex items-center space-x-1">
      <span
        v-if="
          column.filterComponent.numberOfFilters &&
          column.filterComponent.plural
        "
        class="text-white bg-primary rounded-full w-5 h-5 flex items-center justify-center text-center leading-5 text-xxs"
      >
        <span>
          {{ column.filterComponent.numberOfFilters }}
        </span>
      </span>
      <span>
        {{ columnLabel }}
      </span>
    </span>
    <div
      v-if="
        column.filterComponent.hasFiltersSet && column.filterComponent.onClear
      "
      class="transform translate-y-0-25"
      :data-cy="`clear-filter-${props.column.filterComponent.props.name}`"
      @click.stop="column.filterComponent.onClear"
    >
      <icon-base icon="x-circle" />
    </div>

    <span
      v-if="typeof column.filterComponent.sortByChange === 'function'"
      class="transform translate-y-0-25"
      @click.prevent.stop="
        column.filterComponent.sortByChange(column.filterComponent.props.name)
      "
      :data-cy="`table-header-filter-${column.key}-sort`"
    >
      <div>
        <icon-base
          v-if="sortByActive"
          :data-cy="`sort-by-${props.column.filterComponent.props.name}`"
          icon="sort-arrow-active"
          height="18"
          width="18"
          :class="{
            'transform rotate-180': rotateSortByArrow
          }"
        />
        <icon-base
          v-else
          :data-cy="`sort-by-${props.column.filterComponent.props.name}`"
          icon="sort-arrow"
          height="18"
          width="18"
        />
      </div>
    </span>
  </div>
</template>
<script setup lang="ts">
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import IconFilter from "@/components/icons/IconFilter.vue";

const props = defineProps({
  column: {
    type: Object,
    required: true
  },
  toggleFilter: {
    type: Boolean,
    default: false
  },
  // when false will only render toggled inner component when toggled to show
  initialComponentRender: {
    type: Boolean,
    default: true
  },
  scrollableElementId: {
    type: String,
    default: "appWrapper"
  }
});

const emit = defineEmits<{
  (eventName: "closeFilter"): void;
}>();

const { dispatch } = useStore();
const isLoading = ref(false);

const filterIconClasses = computed(() => {
  if (props.toggleFilter) {
    return "bg-primary/10 text-primary";
  }
  const className = props.column?.filterComponent?.hasFiltersSet
    ? "text-primary"
    : "text-label";
  return `${className} hover:bg-grey-hover`;
});

const columnLabel = computed(() =>
  props.column?.filterComponent?.numberOfFilters > 1 ||
  (!props.column?.filterComponent?.numberOfFilters &&
    props.column?.filterComponent?.hasFiltersSet)
    ? props.column?.filterComponent?.plural
    : props.column?.label
);

const sortByActive = computed(
  () =>
    !!props.column?.filterComponent?.sortBy?.includes(
      props.column?.filterComponent?.props?.name
    )
);

const rotateSortByArrow = computed(
  () => !props.column?.filterComponent?.sortBy?.includes("-")
);

const onClickOutsideHandler = [
  () => {
    if (props.initialComponentRender && !props.toggleFilter) {
      return;
    }
    emit("closeFilter");
  },
  { capture: props.initialComponentRender }
];

const handleFilterVisibilityChange = (value: boolean) => {
  if (!value) {
    emit("closeFilter");
  }
};

watch(
  () => props.toggleFilter,
  async (newToggleVal) => {
    if (props.column?.filterComponent?.lazyAction?.action && newToggleVal) {
      isLoading.value = true;
      try {
        await dispatch(
          props.column.filterComponent.lazyAction.action,
          props.column.filterComponent.lazyAction.payload
        );
      } finally {
        isLoading.value = false;
      }
    }
  }
);
</script>
